import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PropertiesContext = createContext({});

export const PropertiesProvider = (props) => {
    const {
        children,
    } = props;

    const [history, setHistory] = useState({});
    const [current_url, setCurrentUrl] = useState('');
    const [initial_url, setInitialUrl] = useState(null);
    const [show_spinner, setShowSpinner] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [user, setUser] = useState(null);
    const [show_login_modal, setShowLoginModal] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState({});

    const authenticate = (value) => {
        setLoggedIn(value);
        if (value === true) {
            setShowLoginModal(false);
        }
    };

    const showLoginModal = (value, history) => {
        setShowLoginModal(value);

        if (history !== null) {
            setHistory(history);
        }
    };

    // Make the context object:
    const context = {
        history,
        setHistory,
        current_url,
        setCurrentUrl,
        initial_url,
        setInitialUrl,
        show_spinner,
        setShowSpinner,
        loggedIn,
        setLoggedIn,
        redirect,
        setRedirect,
        user,
        setUser,
        show_login_modal,
        setShowLoginModal,
        error,
        setError,
        success,
        setSuccess,
        authenticate,
        showLoginModal,
    };

    // pass the value in provider and return
    return <PropertiesContext.Provider value={context}>{children}</PropertiesContext.Provider>;
};


PropertiesProvider.propTypes = {
    history: PropTypes.object,
    current_url: PropTypes.string,
    initial_url: PropTypes.string,
    show_spinner: PropTypes.bool,
    loggedIn: PropTypes.bool,
    redirect: PropTypes.bool,
    user: PropTypes.object,
    show_login_modal: PropTypes.bool,
    error: PropTypes.object,
    success: PropTypes.object,
};

PropertiesProvider.defaultProps = {
    history: {},
    current_url: '',
    initial_url: null,
    show_spinner: false,
    loggedIn: false,
    redirect: false,
    user: null,
    show_login_modal: false,
    error: null,
    success: {},
};

import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { datacollection } from './ear-results';
import { isObject } from './helpers';

export const EARContext = createContext({});

export const EARProvider = (props) => {
    const {
        children,
    } = props;

    const [results, setResults] = useState(null);
    const [currency_type, setCurrencyType] = useState({ label: '(Ksh) Kenyan shilling (KES)', value: 'Ksh' });
    const [quotation_id, setQuotationID] = useState(null);
    const [earthquake_zones, setEarthquakeZones] = useState(null);
    const [extra, setExtra] = useState(null);
    const [site_location, setSiteLocation] = useState(null);
    const [country, setCountry] = useState(null);
    const [discount_percentage, setDiscountPercentage] = useState(null);
    const [deductible, setDeductible] = useState(null);
    const [earthquake_classes, setEarthquakeClasses] = useState(null);
    const [industry_type, setIndustryType] = useState(null);
    const [erection_type, setErectionType] = useState(null);
    const [erection_rates, setErectionRates] = useState(null);

    useEffect(() => {
        setResults(datacollection);
    }, []);

    const saveData = (value) => {
        const obj = {};

        if (value.currency_type && isObject(value.currency_type)) {
            obj.currency_type = value.currency_type.value;
        }
        if (value.site_location && isObject(value.site_location)) {
            obj.site_location = value.site_location.value;
        }
        if (value.discount_percentage && isObject(value.discount_percentage)) {
            obj.discount_percentage = value.discount_percentage.value;
        }
        if (value.industry_type && isObject(value.industry_type)) {
            obj.industry_type = value.industry_type.value;
        }
        if (value.erection_type && isObject(value.erection_type)) {
            obj.erection_type = value.erection_type.value;
        }
        if (value.country && isObject(value.country)) {
            obj.country = value.country.value;
        }

        setResults({ ...results, ...value, ...obj });
    };

    const saveQuotationType = (value) => {
        setResults({ ...results, quotation_type: value });
    };

    const saveQuotationStatus = (value) => {
        setResults({ ...results, status: value });
    };

    const saveLoadingDiscount = (value) => {
        setResults({ ...results, discount_percentage: value });
    };

    // Make the context object:
    const context = {
        results,
        setResults,
        currency_type,
        setCurrencyType,
        quotation_id,
        setQuotationID,
        earthquake_zones,
        setEarthquakeZones,
        extra,
        setExtra,
        country,
        setCountry,
        site_location,
        setSiteLocation,
        deductible,
        setDeductible,
        discount_percentage,
        setDiscountPercentage,
        earthquake_classes,
        setEarthquakeClasses,
        industry_type,
        setIndustryType,
        erection_type,
        setErectionType,
        erection_rates,
        setErectionRates,
        saveData,
        saveQuotationType,
        saveQuotationStatus,
        saveLoadingDiscount,
    };

    // pass the value in provider and return
    return <EARContext.Provider value={context}>{children}</EARContext.Provider>;
};


EARProvider.propTypes = {
    results: PropTypes.object,
    currency_type: PropTypes.object,
    quotation_id: PropTypes.object,
    earthquake_zones: PropTypes.object,
    extra: PropTypes.object,
    site_location: PropTypes.object,
    country: PropTypes.object,
    deductible: PropTypes.object,
    discount_percentage: PropTypes.object,
    earthquake_classes: PropTypes.object,
    industry_type: PropTypes.object,
    erection_type: PropTypes.object,
    erection_rates: PropTypes.object,

};

EARProvider.defaultProps = {
    results: null,
    currency_type: null,
    quotation_id: null,
    earthquake_zones: null,
    extra: null,
    site_location: null,
    country: null,
    deductible: null,
    discount_percentage: null,
    earthquake_classes: null,
    industry_type: null,
    erection_type: null,
    erection_rates: null,
};

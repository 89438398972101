export const Loading = ({ isLoading, error }) => {
    // Handle the loading state
    if (isLoading) {
        return <div>Loading...</div>;
    }
    // Handle the error state
    if (error) {
        console.error(error);
        const text = `Page loading error..... ${error.message}`;
        return <div className="main-error">{text}</div>;
    }
    return null;
};

import { useEffect } from 'react';
import loadable from '@loadable/component';
import { ToastContainer } from 'react-toastify';
import { loadReCaptcha } from 'react-recaptcha-google';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Errors from './errors';
import { Loading } from './loading';
import { ContextProvider } from './state/all';

import 'rc-tabs/assets/index.css';
import './scss/main.scss';
import './scss/tablets-portrait.scss';
import './scss/tablets-landscape.scss';
import './scss/phones-portrait.scss';
import 'react-toastify/dist/ReactToastify.min.css';

const AsyncHeader = loadable(() => import(/* webpackChunkName: "header" */ /* webpackPrefetch: true */ './components/header'), {
    fallback: <Loading />,
});


const AsyncFooter = loadable(() => import(/* webpackChunkName: "footer" */ /* webpackPrefetch: true */ './components/footer'), {
    fallback: <Loading />,
});


const AsyncHome = loadable(() => import(/* webpackChunkName: "home" */ /* webpackPrefetch: true */ './components/home/home-page'), {
    fallback: <Loading />,
});


const AsyncRegister = loadable(() => import(/* webpackChunkName: "register" */ /* webpackPrefetch: true */ './components/register/register-page'), {
    fallback: <Loading />,
});

const AsyncForgot = loadable(() => import(/* webpackChunkName: "register" */ /* webpackPrefetch: true */ './components/forgot/forgot-page'), {
    fallback: <Loading />,
});


const AsyncProfile = loadable(() => import(/* webpackChunkName: "profile" */ /* webpackPrefetch: true */ './components/profile/profile-page'), {
    fallback: <Loading />,
});

const AsyncQuotes = loadable(() => import(/* webpackChunkName: "quotes" */ /* webpackPrefetch: true */ './components/quotes/quotes-page'), {
    fallback: <Loading />,
});

const AsyncLogin = loadable(() => import(/* webpackChunkName: "login" */ /* webpackPrefetch: true */ './components/login/login-page'), {
    fallback: <Loading />,
});


const AsyncContact = loadable(() => import(/* webpackChunkName: "contact" */ /* webpackPrefetch: true */ './components/contact/contact-page'), {
    fallback: <Loading />,
});


const AsyncCatalog = loadable(() => import(/* webpackChunkName: "catalog" */ /* webpackPrefetch: true */ './components/catalog/catalog-page'), {
    fallback: <Loading />,
});


const AsyncMainClass = loadable(() => import(/* webpackChunkName: "mainclass" */ /* webpackPrefetch: true */ './components/catalog/class-detail'), {
    fallback: <Loading />,
});


const AsyncSubClass = loadable(() => import(/* webpackChunkName: "subclass" */ /* webpackPrefetch: true */ './components/catalog/subclass-detail'), {
    fallback: <Loading />,
});


const AsyncQuotation = loadable(() => import(/* webpackChunkName: "quotation" */ /* webpackPrefetch: true */ './components/quotations/main'), {
    fallback: <Loading />,
});


const AsyncQuick = loadable(() => import(/* webpackChunkName: "quick" */ /* webpackPrefetch: true */ './components/quotations/quick'), {
    fallback: <Loading />,
});

const checkEnv = () => {
    const env = `${process.env.REACT_APP_ENV}`;
    switch (env) {
        case 'staging':
        case 'production':
            return '';
        default:
            return '/zepre';
    }
};

const App = props => {

    const { version } = props;

    useEffect(() => {
        loadReCaptcha();
    }, []);

    return (
        <Router basename={checkEnv()}>
            <Errors>
                <ContextProvider>
                    <ToastContainer />
                    <AsyncHeader />
                    <Route exact path="/" component={AsyncHome} />
                    <Route path="/register" component={AsyncRegister} />
                    <Route path="/forgot" component={AsyncForgot} />
                    <Route path="/login" component={AsyncLogin} />
                    <Route path="/profile" component={AsyncProfile} />
                    <Route path="/quotes" component={AsyncQuotes} />
                    <Route path="/contact" component={AsyncContact} />
                    <Route exact path="/catalog" component={AsyncCatalog} />
                    <Route path="/catalog/:id" component={AsyncMainClass} />
                    <Route path="/subclass/:id" component={AsyncSubClass} />
                    <Route exact path="/quotation/:id" component={AsyncQuotation} />
                    <Route exact path="/quickquotation/:id" component={AsyncQuick} />
                    <AsyncFooter version={version} />
                </ContextProvider>
            </Errors>
        </Router>
    );
};

export default App;

export const datacollection = {
    status: 'Draft',
    main_class: 'Property',
    business_class: 'Fire',
    sub_business_class: 'Asset All risks',
    sub_business_class_id: 'aar',
    sub_business_class_key: '-KdQgx8IjFC14SEs72gS',
    quotation_type: 'simple',

    // Form 1
    country: 'Kenya',
    title_of_contract: '',
    site_location: '',
    industry_type: '',
    type: '',
    occupation_type: '',
    commencement_date: '',
    insurance_duration: '',
    insurance_type: 'Asset All Risk',

    // Background Information Form
    year_of_operation: '',
    constructors_names: '',
    raw_materials: '',
    modifications: '',
    persons_employed: '',
    minimum_per_shift: '',
    operating_periods: '',
    full_week: 'No',
    external_exposure: 'Yes',
    describe_proximities: '',
    plant_layout: 'Good',
    specific_plant_space: '',
    site_accessibility: 'Yes',
    flood_occurs: 'No',
    flood_frequency: '',
    water_sensitive: 'No',
    hazards_exposed: '',
    other_hazards_exposed: '',
    aircraft_accidents_exposed: 'No',
    distance_to_airport: '',
    vehicle_movements_hazards: 'No',
    smoke_damage: 'No',
    riots_exposed: 'No',
    riots_exposed_explain: '',
    past_losses: 'Yes',
    past_losses_number: '',
    past_losses_amount: '',
    past_losses_cause: '',
    processes_used: '',
    max_pressures: '',
    max_pressures_section: '',
    max_temperatures: '',
    max_temperatures_section: '',
    products_obtained: '',

    // Site information form
    construction_type: '',
    fire_resistant: 'Yes',
    non_combustible: 'Yes',
    heat_vents: 'Yes',
    state_of_repair: 'Good',
    buildings_separated: 'Yes',
    buildings_separated_explain: '',
    lightning_protection: 'Yes',
    lightning_protection_type: '',
    risk_subdivided: 'Yes',
    risk_subdivided_number: '',
    electric_energy_supplier: '',
    type_of_grid: 'Public grid',
    emergency_generator: 'Yes',
    fixed_fire_fighting_system: 'Yes',
    fighting_system_type: '',
    fire_wall_separated: 'Yes',
    cable_fireproof_sealed: 'No',
    cable_partially_sealed: 'Yes',
    steam_production_facilities: 'No',
    boiler_fuel: 'Oil',
    air_compressors: 'No',
    air_compressors_type: '',
    air_compressors_pressure: '',
    air_compressors_purpose: '',
    water_source: 'Public main',
    water_source_other: '',
    water_source_quantity: '',
    internal_transport: 'Trucks',
    internal_transport_other: '',
    liquid_storage: 'Drums',
    type_of_storage: 'Bulk',
    storage_height: '',
    storage_width: '',
    storage_clearance: '',
    stack_stability: 'Good',
    automatic_roof_vents: 'Yes',
    combustibility: 'High',
    max_size_of_storage: '',
    adjacent_areas_fire_walls: 'No',
    secure_from_external_fire: 'No',
    housekeeping_standard: 'Good',
    waste_disposal: 'Daily',
    smoking_controlled: 'Designated areas',
    hot_work_permit: 'No',
    safety_organised: 'Safety engineer',
    storage_order: 'Good',
    installations_maintenance: 'Yes',
    fire_equipment_inspected: 'Yes',
    fire_equipment_condition: 'Good',

    // Fire Protection form
    hazardous_processes: 'Yes',
    hazardous_processes_explained: '',
    flammable_used: 'Yes',
    flammable_used_explained: '',
    flammable_used_quantity: '',
    explosive_dusts: 'Yes',
    explosive_dusts_type: '',
    manual_alarm_systems: 'Yes',
    other_alerting_systems: 'Telephones',
    connected_to_fire_station: 'No',
    types_of_automatic_system: 'Smoke',
    automatic_system_number: '',
    detectors_covered_areas: '',
    central_alarm_station: 'Gatehouse',
    public_water_connection: 'Yes',
    main_diameter_pressure: '',
    own_water_supply: 'Yes',
    own_water_supply_type: 'Tank(s)',
    own_water_supply_type_other: '',
    min_water_amount: '<500m3',
    fire_pumps: 'Manually operated',
    fire_pumps_number: '',
    fire_pumps_capacities: '',
    min_fire_mains_diameter: '',
    max_fire_mains_diameter: '',
    dry_wet_risers: 'Yes',
    hydrants_description: '',
    ring_main: 'Yes',
    hose_connections: 'Yes',
    powder_extinguishers: 'No',
    powder_extinguishers_number: '',
    powder_extinguishers_size: '',
    water_extinguishers: 'No',
    water_extinguishers_number: '',
    water_extinguishers_size: '',
    co2_extinguishers: 'Yes',
    co2_extinguishers_number: '',
    co2_extinguishers_size: '',
    halon_extinguishers: 'No',
    halon_extinguishers_number: '',
    halon_extinguishers_size: '',
    extinguishers_distribution: 'Good',
    extinguishers_maintenance: 'Good',
    extinguishers_marking: 'Good',
    extinguishers_maintained_by: '',
    automatic_sprinkler_installed: 'Yes',
    automatic_sprinkler_type: 'Wet',
    automatic_sprinkler_areas: '',
    water_deluge_installed: 'No',
    water_deluge_areas: '',
    powder_co_halon_installed: 'No',
    powder_co_halon_type: 'CO2',
    powder_co_halon_areas: '',
    foam_extinguishers_installed: 'No',
    foam_extinguishers_areas: '',
    extinguishers_installation_standards: '',
    private_fire_brigade: 'No',
    brigade_fulltime: 'No',
    brigade_min_staff: '',
    support_fire_brigade: 'Yes',
    volunteer_number: '',
    regular_fire_drills: 'Monthly',
    private_fire_station: 'No',
    fire_trucks_available: 'No',
    fire_trucks_number: '',
    stock_fighting_agents: 'Yes',
    powder_quantity: '',
    foam_quantity: '',
    public_fire_brigade_distance: '',
    public_fire_brigade_response: '',
    public_fire_brigade_fulltime: 'Part time',
    public_fire_brigade_equipment: 'Fair',
    public_fire_brigade_familiar: 'No',
    public_fire_brigade_agreement: 'No',

    // Form 2
    area_discount: 'No',
    area_discount_amount: '',
    construction_class: '',
    sprinkler_discount: 'No',
    sprinkler_discount_amount: '',
    appliances_discount: 'No',
    appliances_discount_amount: '',
    long_term_discount: 'No',
    long_term_discount_amount: '',
    loss_ratio_above_50: 'No',
    loss_ratio_above_50_amount: '',
    roofs_loading: 'No',
    roofs_loading_amount: '',
    strike_loading: 'No',
    strike_loading_amount: '',
    perils_loading: 'No',
    perils_loading_amount: '',
    earthquake_loading: 'No',
    earthquake_loading_amount: '',

    // Form 3
    currency_type: 'Ksh',
    insured_maximum_loss_amount: '',
    gross_profit: '',
    increased_cost_of_working: '',
    auditors_fees: '',
    other_business_interruption: '',
    business_maximum_loss_amount: '',
    interruption_months: '12 months',

    // Discounts and Loading
    increase_deductibles: 'No',
    increase_deductibles_by: '',

    include_loading_discount: 'No',
    loading_or_discount: '',
    discount_percentage: '',
    loading_or_discount_message: '',

    // Indemnity
    excess_time: '',
    indemnity_discount: '',
};
import { cloneElement } from 'react';
import { withRouter } from 'react-router-dom';
import { ContentProvider } from './content';
import { PropertiesProvider } from './properties';
import { MDIProvider } from './mdi';
import { CARProvider } from './car';
import { EARProvider } from './ear';
import { AARProvider } from './aar';

function ProviderComposer({ contexts, children }) {
    return contexts.reduceRight(
        (kids, parent) => cloneElement(parent, {
            children: kids,
        }),
        children,
    );
}

const ContextProvider = withRouter(({ children }) => (
    <ProviderComposer contexts={[<PropertiesProvider />, <ContentProvider />, <MDIProvider />, <AARProvider />, <CARProvider />, <EARProvider />]}>
        {children}
    </ProviderComposer>
));

export { ContextProvider };

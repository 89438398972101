export const datacollection = {
    status: 'Draft',
    main_class: 'Property',
    business_class: 'Engineering',
    sub_business_class: "Contractors' all risks / public liability",
    sub_business_class_id: 'car',
    sub_business_class_key: '-KdQhSfeiowfWJLPCsT-',
    quotation_type: 'simple',

    // Section 1
    // Form 1
    country: 'Kenya',
    title_of_contract: '',
    site_location: '',
    principal_name_address: '',
    contractor_name_address: '',
    subcontractor_name_address: '',
    consulting_engineer_name_address: '',
    structure_type: '',
    type: '',
    dimensions: '',
    foundation_type: '',
    construction_method: '',
    construction_materials: '',
    contractor_experienced: '',
    commencement_date: '',
    construction_duration: '',
    completion_date: '',
    maintenance_period: '',
    subcontractors_work: '',

    // Form 2
    construction_type: '',
    construction_type_number: '',
    show_basement: 'Yes',
    have_basements: '',
    number_of_storeys: '',
    number_of_basements: '',
    span: '',
    fire: 'Yes',
    flood: 'Yes',
    landslide: 'Yes',
    blasting: 'Yes',
    other_risks: 'No',
    other_risks_details: '',
    volcanism: 'Yes',
    design_earthquake_regulations: 'Yes',

    // Form 3
    // Section 1
    currency_type: 'Ksh',
    contract_price_amount: '',
    supplies_by_principal_amount: '',
    construction_equipment_amount: '',
    construction_machinery_amount: '',
    debris_clearance_amount: '',
    section_one_sum_amount: '',
    natural_peril_limit_amount: '',

    // Section 2
    include_third_party_liability: 'No',
    bodily_injury_one_person_amount: '',
    bodily_injury_total_amount: '',
    property_damage_amount: '',
    section_two_sum_amount: '',

    // Section 3
    increase_deductibles: 'No',
    increase_deductibles_by: '',

    include_loading_discount: 'No',
    loading_or_discount: '',
    discount_percentage: '',
    loading_or_discount_message: '',

    // Deductibles
    all_others_user_deductible: '',
    aog_user_deductible: '',
    tpl_user_deductible: '',

};
import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { datacollection } from './aar-results';
import { isObject } from './helpers';

export const AARContext = createContext({});

export const AARProvider = (props) => {
    const {
        children,
    } = props;

    const [results, setResults] = useState(null);
    const [currency_type, setCurrencyType] = useState({ label: '(Ksh) Kenyan shilling (KES)', value: 'Ksh' });
    const [interruption_months, setInterruptionMonths] = useState({ value: '12 months', label: '12 months', rate: 150 });
    const [quotation_id, setQuotationID] = useState(null);
    const [earthquake_zones, setEarthquakeZones] = useState(null);
    const [excess_time, setExcessTime] = useState(null);
    const [country, setCountry] = useState(null);
    const [extra, setExtra] = useState(null);
    const [site_location, setSiteLocation] = useState(null);
    const [occupation_type, setOccupationType] = useState(null);
    const [construction_class, setConstructionClass] = useState(null);
    const [deductible, setDeductible] = useState(null);
    const [discount_percentage, setDiscountPercentage] = useState(null);

    useEffect(() => {
        setResults(datacollection);
    }, []);

    const saveData = (value) => {
        const obj = {};

        if (value.currency_type && isObject(value.currency_type)) {
            obj.currency_type = value.currency_type.value;
        }
        if (value.interruption_months && isObject(value.interruption_months)) {
            obj.interruption_months = value.interruption_months.value;
        }
        if (value.excess_time && isObject(value.excess_time)) {
            obj.excess_time = value.excess_time.value;
        }
        if (value.site_location && isObject(value.site_location)) {
            obj.site_location = value.site_location.value;
        }
        if (value.occupation_type && isObject(value.occupation_type)) {
            obj.occupation_type = value.occupation_type.value;
        }
        if (value.construction_class && isObject(value.construction_class)) {
            obj.construction_class = value.construction_class.value;
        }
        if (value.discount_percentage && isObject(value.discount_percentage)) {
            obj.discount_percentage = value.discount_percentage.value;
        }
        if (value.country && isObject(value.country)) {
            obj.country = value.country.value;
        }

        setResults({ ...results, ...value, ...obj });
    };

    const saveQuotationType = (value) => {
        setResults({ ...results, quotation_type: value });
    };

    const saveQuotationStatus = (value) => {
        setResults({ ...results, status: value });
    };

    const saveLoadingDiscount = (value) => {
        setResults({ ...results, discount_percentage: value });
    };

    // Make the context object:
    const context = {
        results,
        setResults,
        currency_type,
        setCurrencyType,
        interruption_months,
        setInterruptionMonths,
        quotation_id,
        setQuotationID,
        earthquake_zones,
        setEarthquakeZones,
        excess_time,
        setExcessTime,
        extra,
        setExtra,
        country,
        setCountry,
        site_location,
        setSiteLocation,
        occupation_type,
        setOccupationType,
        construction_class,
        setConstructionClass,
        deductible,
        setDeductible,
        discount_percentage,
        setDiscountPercentage,
        saveData,
        saveQuotationType,
        saveQuotationStatus,
        saveLoadingDiscount,
    };

    // pass the value in provider and return
    return <AARContext.Provider value={context}>{children}</AARContext.Provider>;
};


AARProvider.propTypes = {
    results: PropTypes.object,
    currency_type: PropTypes.object,
    interruption_months: PropTypes.object,
    quotation_id: PropTypes.object,
    earthquake_zones: PropTypes.object,
    excess_time: PropTypes.object,
    extra: PropTypes.object,
    country: PropTypes.object,
    site_location: PropTypes.object,
    occupation_type: PropTypes.object,
    construction_class: PropTypes.object,
    deductible: PropTypes.object,
    discount_percentage: PropTypes.object,

};

AARProvider.defaultProps = {
    results: null,
    currency_type: null,
    interruption_months: null,
    quotation_id: null,
    earthquake_zones: null,
    excess_time: null,
    extra: null,
    country: null,
    site_location: null,
    occupation_type: null,
    construction_class: null,
    deductible: null,
    discount_percentage: null,
};

import CacheBuster from './CacheBuster';
import App from './app';

const Root = (props) => (
    <CacheBuster>
        {({
            latestVersion, loading, isLatestVersion, refreshCacheAndReload,
        }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
            }

            return <App version={latestVersion} {...props} />;
        }}
    </CacheBuster>
);

export default Root;

export const datacollection = {
    status: 'Draft',
    main_class: 'Property',
    business_class: 'Engineering',
    sub_business_class: 'Erection All risks / public liability',
    sub_business_class_id: 'ear',
    sub_business_class_key: '-KdQh_TwOZGIO59GlPCK',
    quotation_type: 'simple',

    // Section 1
    // Form 1
    country: 'Kenya',
    title_of_contract: '',
    site_location: '',
    principal_name_address: '',
    contractor_name_address: '',
    subcontractor_name_address: '',
    manufacturer_name_address: '',
    supervising_firm_name_address: '',
    consulting_engineer_name_address: '',
    industry_type: '',
    type: '',
    work_description: '',
    contractor_experienced: '',
    commencement_date: '',
    prestorage_duration: '',
    erection_commencement_date: '',
    erection_duration: '',
    testing_duration: '',
    maintenance_duration: '',
    subcontractors_work: '',

    // Form 2
    erection_type: '',
    erection_type_number: '',
    site_elevation: '',
    distance_to_water: '',
    rainfall_intensities: '',
    wind_speed: '',
    fire: 'Yes',
    flood: 'Yes',
    landslide: 'Yes',
    blasting: 'Yes',
    other_risks: 'No',
    other_risks_details: '',
    volcanism: 'Yes',
    design_earthquake_regulations: 'Yes',
    include_manufacturers_risk: 'No',

    // Form 3
    // Section 1
    currency_type: 'Ksh',
    items_erected_amount: '',
    freight_amount: '',
    custom_duty_amount: '',
    erection_cost_amount: '',
    civil_engineering_work_amount: '',
    erection_equipment_amount: '',
    property_location_amount: '',
    debris_clearance_amount: '',
    section_one_sum_amount: '',
    natural_peril_limit_amount: '',

    // Section 2
    include_third_party_liability: 'No',
    bodily_injury_one_person_amount: '',
    bodily_injury_total_amount: '',
    property_damage_amount: '',
    section_two_sum_amount: '',

    // Section 3
    increase_deductibles: 'No',
    increase_deductibles_by: '',

    include_loading_discount: 'No',
    loading_or_discount: '',
    discount_percentage: '',
    loading_or_discount_message: '',

    // Deductibles
    all_others_user_deductible: '',
    aog_user_deductible: '',
    tpl_user_deductible: '',

};
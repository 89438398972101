import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { datacollection } from './car-results';
import { isObject } from './helpers';

export const CARContext = createContext({});

export const CARProvider = (props) => {
    const {
        children,
    } = props;

    const [results, setResults] = useState(null);
    const [currency_type, setCurrencyType] = useState({ label: '(Ksh) Kenyan shilling (KES)', value: 'Ksh' });
    const [quotation_id, setQuotationID] = useState(null);
    const [earthquake_zones, setEarthquakeZones] = useState(null);
    const [extra, setExtra] = useState(null);
    const [site_location, setSiteLocation] = useState(null);
    const [country, setCountry] = useState(null);
    const [discount_percentage, setDiscountPercentage] = useState(null);
    const [deductible, setDeductible] = useState(null);
    const [structure_type, setStructureType] = useState(null);
    const [construction_type, setConstructionType] = useState(null);
    const [show_basement, setShowBasement] = useState(null);
    const [property_rates, setPropertyRates] = useState(null);
    const [earthquake_classes, setEarthquakeClasses] = useState(null);

    useEffect(() => {
        setResults(datacollection);
    }, []);

    const saveData = (value) => {

        const obj = {};

        if (value.currency_type && isObject(value.currency_type)) {
            obj.currency_type = value.currency_type.value;
        }
        if (value.site_location && isObject(value.site_location)) {
            obj.site_location = value.site_location.value;
        }
        if (value.discount_percentage && isObject(value.discount_percentage)) {
            obj.discount_percentage = value.discount_percentage.value;
        }
        if (value.structure_type && isObject(value.structure_type)) {
            obj.structure_type = value.structure_type.value;
        }
        if (value.construction_type && isObject(value.construction_type)) {
            obj.construction_type = value.construction_type.value;
        }
        if (value.show_basement && isObject(value.show_basement)) {
            obj.show_basement = value.show_basement.value;
        }
        if (value.country && isObject(value.country)) {
            obj.country = value.country.value;
        }

        setResults({ ...results, ...value, ...obj });
    };

    const saveQuotationType = (value) => {
        setResults({ ...results, quotation_type: value });
    };

    const saveQuotationStatus = (value) => {
        setResults({ ...results, status: value });
    };

    const saveLoadingDiscount = (value) => {
        setResults({ ...results, discount_percentage: value });
    };

    const startNew = () => {
        setResults(datacollection);
        setCurrencyType({ label: '(Ksh) Kenyan shilling (KES)', value: 'Ksh' });
        setQuotationID(null);
        setEarthquakeZones(null);
        setExtra(null);
        setSiteLocation(null);
        setDiscountPercentage(null);
        setDeductible(null);
        setStructureType(null);
        setConstructionType(null);
        setShowBasement(null);
        setPropertyRates(null);
        setEarthquakeClasses(null);
    };

    // Make the context object:
    const context = {
        startNew,
        results,
        setResults,
        currency_type,
        setCurrencyType,
        quotation_id,
        setQuotationID,
        earthquake_zones,
        setEarthquakeZones,
        extra,
        setExtra,
        site_location,
        setSiteLocation,
        country,
        setCountry,
        deductible,
        setDeductible,
        discount_percentage,
        setDiscountPercentage,
        structure_type,
        setStructureType,
        construction_type,
        setConstructionType,
        show_basement,
        setShowBasement,
        property_rates,
        setPropertyRates,
        earthquake_classes,
        setEarthquakeClasses,
        saveData,
        saveQuotationType,
        saveQuotationStatus,
        saveLoadingDiscount,
    };

    // pass the value in provider and return
    return <CARContext.Provider value={context}>{children}</CARContext.Provider>;
};


CARProvider.propTypes = {
    results: PropTypes.object,
    currency_type: PropTypes.object,
    quotation_id: PropTypes.object,
    earthquake_zones: PropTypes.object,
    extra: PropTypes.object,
    site_location: PropTypes.object,
    country: PropTypes.object,
    deductible: PropTypes.object,
    discount_percentage: PropTypes.object,
    structure_type: PropTypes.object,
    construction_type: PropTypes.object,
    show_basement: PropTypes.object,
    property_rates: PropTypes.object,
    earthquake_classes: PropTypes.object,

};

CARProvider.defaultProps = {
    results: null,
    currency_type: null,
    quotation_id: null,
    earthquake_zones: null,
    extra: null,
    site_location: null,
    country: null,
    deductible: null,
    discount_percentage: null,
    structure_type: null,
    construction_type: null,
    show_basement: null,
    property_rates: null,
    earthquake_classes: null,

};

import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ContentContext = createContext({});

export const ContentProvider = (props) => {
    const {
        children,
    } = props;

    const [main_classes, setMainClasses] = useState({});
    const [business_classes, setBusinessClasses] = useState({});
    const [business_sub_classes, setBusinessSubClasses] = useState({});

    // Make the context object:
    const context = {
        main_classes,
        setMainClasses,
        business_classes,
        setBusinessClasses,
        business_sub_classes,
        setBusinessSubClasses,
    };

    // pass the value in provider and return
    return <ContentContext.Provider value={context}>{children}</ContentContext.Provider>;
};

ContentProvider.propTypes = {
    main_classes: PropTypes.object,
    business_classes: PropTypes.object,
    business_sub_classes: PropTypes.object,
};

ContentProvider.defaultProps = {
    main_classes: {},
    business_classes: {},
    business_sub_classes: {},
};
